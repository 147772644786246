export enum TaskPriorityEnum {
  LOW = '000_low',
  NORMAL = '010_normal',
  HIGH = '020_high',
  CRITICAL = '030_critical',
}

export enum TaskSortFieldEnum {
  TITLE = 'title',
  PRIORITY = 'priority',
  DUE_AT = 'dueAt',
}

export enum TaskFilterEnum {
  PRIORITY = 'priority',
  ASSIGNEE_ID = 'assigneeId',
  DUE_AT = 'dueAt',
  IS_DONE = 'isDone',
  CREATOR_ID = 'creatorId',
  CASE_IDS = 'caseIds',
  ASSET_IDS = 'assetIds',
  WORK_ORDER_IDS_2 = 'workOrderIds2',
  ORGANIZATION_IDS = 'organizationIds',
}

export enum TaskCreationMethodEnum {
  IN_LINE = 'in-line',
  DIALOG = 'dialog',
}

export enum TaskCreationSourceFeatureEnum {
  TASKS = 'tasks',
  CASES = 'cases',
  WORK_ORDERS = 'work orders',
  WORK_ORDERS_2 = 'work orders 2',
  FORMS = 'forms',
  ORGANIZATIONS = 'organizations',
  PARTS = 'parts',
  ASSETS = 'assets',
}

export enum TasksDialogType {
  ADD = 'addTaskDialog',
  EDIT = 'editTaskDialog',
}

export enum TasksTableColumnEnum {
  IS_DONE = 'isDone',
  TITLE = 'title',
  PRIORITY = 'priority',
  ASSOCIATIONS = 'associations',
  ASSIGNEE = 'assignee',
  DUE_AT = 'dueAt',
}

export enum TasksViewTypeEnum {
  MY_OPEN_TASKS = 'myOpenTasks',
  MY_TASKS = 'myTasks',
  OPEN_TASKS_CREATED_BY_ME = 'openTasksCreatedByMe',
  TASKS_CREATED_BY_ME = 'tasksCreatedByMe',
}

export enum TasksStateIdsEnum {
  TASKS_PAGE = 'tasksPage',
  CASE_DETAILS_PAGE = 'caseDetailsPage',
  ASSETS_DETAIL_PAGE = 'assetsDetailsPage',
  WORK_ORDER_DETAIL_PAGE = 'workOrderDetailPage',
  WORK_ORDER_DETAIL_PAGE_2 = 'workOrderDetailPage2',
  ORGANIZATION_DETAIL_PAGE = 'organizationDetailPage',
}

export enum TaskCardTableColumNameEnum {
  CARD = 'card',
}
