// see full api documentation here -> https://sql.js.org/

// library is currently not compatible with js bundler - https://github.com/sql-js/sql.js/issues/544
// this is why we only use import the type here
import type initSqlJs from 'sql.js/dist/sql-wasm.js';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { VERSION } from '@remberg/global/common/version';
import {
  environment,
  LocalStorageKeys,
  LogService,
  PrefetchOnlyDataTypesEnum,
  SyncDataTypesEnum,
  timestampLocalStorageKeyMap,
} from '@remberg/global/ui';
import { Tenant } from '@remberg/tenants/common/main';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, filter, firstValueFrom } from 'rxjs';
import { LATEST_DB_VERSION } from '../../helpers/sqlDbHelper';
import { GlobalSelectors, RootGlobalState } from '../../store';
import { AppStateService } from '../app-state.service';
import { generateDBSnapshotFileName, ParsedFileName, parseFileName } from './helpers';
import { SQLiteObjectMock } from './sqlite-object-mock';

declare global {
  interface Window {
    SQL: initSqlJs.SqlJsStatic;
    debugDB: initSqlJs.Database;
  }
}

@Injectable({
  providedIn: 'root',
})
export class SqlDBMockService {
  private db?: initSqlJs.Database;
  private isDbReady = new BehaviorSubject<boolean>(false);

  constructor(
    private logger: LogService,
    private readonly appState: AppStateService,
    private readonly toastr: ToastrService,
    private readonly http: HttpClient,
    private store: Store<RootGlobalState>,
  ) {}

  public async initialize(): Promise<void> {
    const simulatedIonicType = (
      await firstValueFrom(
        this.store.select(GlobalSelectors.selectDeviceType).pipe(filter(Boolean)),
      )
    ).simulatedIonicType;
    if (environment.debug && simulatedIonicType) {
      await this.loadScript();
      // Required to load the wasm binary asynchronously.
      window.SQL = await window.initSqlJs({ locateFile: () => '/assets/sql-js/sql-wasm.wasm' });
      this.isDbReady.next(true);
    }
  }

  // sql-js library is currently not compatible with a js bundler
  // https://github.com/sql-js/sql.js/issues/544
  private async loadScript(): Promise<void> {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = '/assets/sql-js/sql-wasm.js';

      script.onload = (): void => resolve();

      script.onerror = (): void => {
        this.logger.error()(
          'Failed loading sql-wasm.js. Make sure it is provided in /assets/sql-js/sql-wasm.js',
        );
        reject();
      };

      document.head.appendChild(script);
    });
  }

  public async create(): Promise<SQLiteObjectMock> {
    this.logger.warn()('Using mocked SQLite Database!');

    // wait in case the webassembly SQL library is not initialize yet
    // maximum 5 seconds
    await Promise.race([
      firstValueFrom(this.isDbReady.pipe(filter((val) => val))),
      new Promise((_, reject) =>
        setTimeout(() => {
          reject(new Error('Database initialization timed out.'));
        }, 5000),
      ),
    ]);

    return this.createEmptyOrPreloadDbSnapshot();
  }

  public createSQLiteObject(fromExistingArrayBuffer?: Uint8Array): SQLiteObjectMock | undefined {
    if (fromExistingArrayBuffer) {
      try {
        const dbFromFile = new window.SQL.Database(fromExistingArrayBuffer);
        // if the provided ArrayBuffer is not a valid database any table lookup will throw an error
        const testArray = dbFromFile.exec(`PRAGMA table_info(${SyncDataTypesEnum.WORKORDERS2});`);
        if (!Array.isArray(testArray)) {
          return undefined;
        }
        this.db = dbFromFile;
      } catch (error) {
        this.logger.error()(error);
        return undefined;
      }
    } else {
      this.db = new window.SQL.Database();
    }

    window.debugDB = this.db;

    return new SQLiteObjectMock(this.db);
  }

  // export the created database as a JavaScript typed array
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Typed_arrays
  public async exportDB(): Promise<void> {
    const lastPrefetchTimeString = this.appState.getValue(LocalStorageKeys.OFFLINE_LAST_UPDATED);
    if (!this.db) {
      throw new Error('Database not initialized');
    }

    if (!lastPrefetchTimeString) {
      throw new Error('No Data sync timestamp found.');
    }

    const curServerName = this.appState.getValue(LocalStorageKeys.IONIC_CURRENT_SERVER_NAME);
    if (!curServerName) {
      throw new Error('There seems to be no server name defined');
    }

    let curTenant = this.appState.getValue(LocalStorageKeys.SUBDOMAIN);
    if (!curTenant) {
      const tenant = JSON.parse(
        this.appState.getValue(LocalStorageKeys.IONIC_CURRENT_TENANT) ?? '',
      ) as Tenant;
      curTenant = tenant?.subdomain;
    }

    if (!curTenant) {
      throw new Error('There seems to be no tenant subdomain defined');
    }

    const numberValue: initSqlJs.SqlValue = await this.db.exec('PRAGMA user_version;', [])[0]
      ?.values?.[0]?.[0];

    if (typeof numberValue !== 'number') {
      throw new Error('Database version could not be determined');
    }

    const arraybuff = this.db.export();
    const blob = new Blob([arraybuff]);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = window.URL.createObjectURL(blob);
    a.download = generateDBSnapshotFileName(
      curServerName,
      curTenant,
      lastPrefetchTimeString,
      numberValue,
      VERSION,
    );

    return await new Promise<void>((resolve) => {
      a.onclick = (): void => {
        setTimeout(() => {
          window.URL.revokeObjectURL(a.href);
          resolve();
        }, 1500);
      };
      a.click();
    });
  }

  public async importDB(fromEvent?: Event, fromUrl?: string): Promise<SQLiteObjectMock> {
    const curServerName = this.appState.getValue(LocalStorageKeys.IONIC_CURRENT_SERVER_NAME);
    let curTenant = this.appState.getValue(LocalStorageKeys.SUBDOMAIN);

    const rawTenant = this.appState.getValue(LocalStorageKeys.IONIC_CURRENT_TENANT);
    if (!curTenant && rawTenant) {
      const account = JSON.parse(rawTenant);
      curTenant = account.subdomain;
    }

    let arrayBuffer: Uint8Array | undefined = undefined;
    let fileInfo: ParsedFileName | null = null;

    if (fromEvent) {
      const inputElement = fromEvent.target as HTMLInputElement;
      const files = inputElement.files;

      if (!files || files.length === 0) {
        throw new Error('No file selected');
      }

      const file = files[0];
      fileInfo = parseFileName(file.name);

      const fileReader = new FileReader();

      // Use Promise for FileReader events
      arrayBuffer = await new Promise<Uint8Array>((resolve, reject) => {
        fileReader.onload = (): void => {
          if (fileReader.result instanceof ArrayBuffer) {
            resolve(new Uint8Array(fileReader.result));
          } else {
            reject(new Error('Invalid file format'));
          }
        };

        fileReader.onerror = (): void => {
          reject(new Error('File reading error'));
        };

        fileReader.readAsArrayBuffer(file);
      });
    } else if (fromUrl) {
      fileInfo = parseFileName(fromUrl);
      arrayBuffer = await this.loadDBSnapshotFromUrl('/assets/' + fromUrl);
    }

    if (!fileInfo) {
      throw new Error('File name is invalid');
    }

    if (fileInfo.curServerName !== curServerName) {
      this.toastr.warning(
        'Be aware that this database was created with a different server endpoint.',
        'Different server endpoint',
      );
    }

    if (curTenant && fileInfo.tenant !== curTenant) {
      throw new Error('This database was created from a different Tenant.');
    }

    const dbObject = this.createSQLiteObject(arrayBuffer);
    if (!dbObject) {
      throw new Error('File is not a database');
    }

    this.setLastUpdatedTimestampsTo(fileInfo.lastSyncTimeString);

    return dbObject;
  }

  private async createEmptyOrPreloadDbSnapshot(): Promise<SQLiteObjectMock> {
    // using localStorage directly as SIMULATE_IONIC_PRELOAD_DB will not be part of appState when added manually
    const preloadSnapshot = localStorage.getItem(LocalStorageKeys.SIMULATE_IONIC_PRELOAD_DB);
    if (preloadSnapshot) {
      return await this.importDB(undefined, preloadSnapshot);
    }

    // Since this is a mock db, we can ignore empty initialization errors
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const emptyDB = this.createSQLiteObject()!;
    emptyDB.executeSql(`PRAGMA user_version = ${LATEST_DB_VERSION};`, []);

    return emptyDB;
  }

  public async loadDBSnapshotFromUrl(url: string): Promise<Uint8Array> {
    try {
      const response = await firstValueFrom(this.http.get(url, { responseType: 'arraybuffer' }));
      return new Uint8Array(response);
    } catch (error) {
      const msg = `Failed to download ${LocalStorageKeys.SIMULATE_IONIC_PRELOAD_DB} with url: ${url}: ${error}`;
      this.toastr.error(msg);
      this.logger.error()(msg);
      throw error;
    }
  }

  private setLastUpdatedTimestampsTo(updateDate: Date): void {
    this.appState.setValue(LocalStorageKeys.OFFLINE_LAST_UPDATED, String(updateDate));

    for (const [key, value] of Object.entries(timestampLocalStorageKeyMap)) {
      // do not update the timestamps for icons and files because they are not included in the snapshot
      if (key !== PrefetchOnlyDataTypesEnum.ICONS && key !== SyncDataTypesEnum.FILES) {
        this.appState.setValue(value, String(updateDate));
      }
    }
  }

  public get dbPreloadingEnabled(): boolean {
    // using localStorage directly as SIMULATE_IONIC_PRELOAD_DB will not be part of appState when added manually
    return !!localStorage.getItem(LocalStorageKeys.SIMULATE_IONIC_PRELOAD_DB);
  }
}
