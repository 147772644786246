// TODO use value of this enum instead of individual string values

// TODO: Rename to PrimaryRoutes in future
export enum MainRoutes {
  ROOT = '',
  NOT_FOUND = 'notfound',
  FORGOT = 'forgot',
  ASSETS = 'assets',
  SERVICE_CASES = 'servicecases',
  TICKETS2 = 'tickets2',
  ORGANIZATIONS = 'organizations',
  CONTACTS = 'contacts',
  FORMS = 'forms',
  FORMS2 = 'forms2',
  FILE_DRIVE = 'filedrive',
  DASHBOARD = 'dashboard',
  WORK_ORDERS_2 = 'workorders2',
  APPOINTMENTS = 'appointments',
  TASKS = 'tasks',
  PARTS = 'parts',
  MAP = 'map',
  SETTINGS = 'settings',
  QRCODE = 'qrcode',
  USER = 'user',
  PUBLIC = 'public',
}
