import { ContactBasic } from '@remberg/crm/common/base';
import { FormInstanceCreateFromEmptyBody } from '@remberg/forms/common/dtos';
import {
  AddressInputFieldData,
  AssetMultiSelectFieldData,
  AssetSingleSelectFieldData,
  BooleanInputFieldData,
  ContactSingleSelectFieldData,
  DateInputFieldData,
  DateTimeInputFieldData,
  ExpenseListInputFieldData,
  FileUploadFieldData,
  FormEmailSectionData,
  FormField,
  FormFieldData,
  FormFieldSectionData,
  FormFieldTypesEnum,
  FormInstanceData,
  FormInstanceRaw,
  FormInstanceStatusEnum,
  FormSection,
  FormSectionData,
  FormSectionTypesEnum,
  FormSignatureSectionData,
  FormTemplate,
  FormTemplateConfig,
  HeadlineDisplayFieldData,
  HtmlDisplayFieldData,
  MultiLineTextInputFieldData,
  OrganizationSingleSelectFieldData,
  PartListInputFieldData,
  PersonListInputFieldData,
  PhoneNumberInputFieldData,
  RepeaterFieldData,
  RichTextInputFieldData,
  SingleLineTextInputFieldData,
  SparePartListInputFieldData,
  StaticMultiSelectFieldData,
  StaticSingleSelectFieldData,
  TaskListInputFieldData,
  TimeInputFieldData,
  TimeTrackingListInputFieldData,
} from '@remberg/forms/common/main';
import { IsoLanguageCodesEnum } from '@remberg/global/common/core';
import { UnreachableCaseError, as, generateObjectId, getDefinedOrThrow } from '@remberg/global/ui';
import { toRembergDate } from '../services';

export function createFormInstance(
  creator: ContactBasic,
  tenantId: string,
  formTemplate: FormTemplate,
  model: FormInstanceCreateFromEmptyBody,
): FormInstanceRaw {
  const createdAt = new Date().toISOString();
  const name = createDefaultName(formTemplate, model.pdfLanguage);

  const formTemplateVersion = getDefinedOrThrow(
    formTemplate.formTemplateVersion,
    'createFormInstance: FormTemplateVersion is undefined',
  );
  const formTemplateConfig = getDefinedOrThrow(
    formTemplateVersion.formTemplateConfig,
    'createFormInstance: FormTemplateVersion.formTemplateConfig is undefined',
  );

  const pdfLanguage = getIsoLanguageCodeValidated(
    model.pdfLanguage,
    formTemplateVersion.allowedPdfLanguages,
  );

  const data = createFormInstanceDataStructure(formTemplateConfig);

  return {
    _id: generateObjectId(),
    counter: 0,
    name,
    formTemplateId: formTemplate._id,
    formTemplateVersionId: formTemplate.formTemplateVersion?._id ?? '',
    createdById: creator._id,
    updatedById: creator._id,
    tenantId: tenantId,
    organizationId: creator.organizationId,
    createdAt: createdAt,
    updatedAt: createdAt,
    dateModified: createdAt,
    status: FormInstanceStatusEnum.IN_PROGRESS,
    assigneeId: model.assigneeId,
    pdfLanguage,
    relatedWorkOrderId2: model.relatedWorkOrderId2,
    relatedOrganizationIds: [],
    relatedAssetIds: [],
    data,
  };
}

export function createDefaultName(formTemplate: FormTemplate, lang?: IsoLanguageCodesEnum): string {
  const currentDate = toRembergDate(new Date());
  let templateName;
  if (lang) {
    templateName = formTemplate.name[lang];
  } else {
    templateName = Object.values(formTemplate.name)[0];
  }

  return `${currentDate}_${templateName}`;
}

export function getIsoLanguageCodeValidated(
  pdfLanguage: IsoLanguageCodesEnum | undefined,
  allowedPdfLanguages: IsoLanguageCodesEnum[],
): IsoLanguageCodesEnum {
  const lang = pdfLanguage || IsoLanguageCodesEnum.DE;

  if (allowedPdfLanguages.includes(lang)) {
    return lang;
  }

  return getDefinedOrThrow(allowedPdfLanguages[0]);
}

export function createFormInstanceDataStructure(
  config: FormTemplateConfig,
): FormInstanceData<false> {
  return (config.sections ?? []).map(createFormInstanceDataSection);
}

function createFormInstanceDataSection(section: FormSection): FormSectionData<false> {
  switch (section.type) {
    case FormSectionTypesEnum.FIELD_SECTION: {
      return as<FormFieldSectionData<false>>({
        fields: (section.fields ?? []).map(createFormInstanceFieldDataSection),
      });
    }
    case FormSectionTypesEnum.SIGNATURE_SECTION: {
      return as<FormSignatureSectionData>({});
    }
    case FormSectionTypesEnum.EMAIL_SECTION: {
      return as<FormEmailSectionData>({
        emailList: [],
      });
    }
    default:
      throw new UnreachableCaseError(section);
  }
}

function createFormInstanceFieldDataSection(field: FormField): FormFieldData<false> {
  switch (field.type) {
    case FormFieldTypesEnum.SINGLE_LINE_TEXT_INPUT: {
      return as<SingleLineTextInputFieldData>({});
    }
    case FormFieldTypesEnum.ASSET_SINGLE_SELECT: {
      return as<AssetSingleSelectFieldData<false>>({});
    }
    case FormFieldTypesEnum.MULTI_LINE_TEXT_INPUT: {
      return as<MultiLineTextInputFieldData>({});
    }
    case FormFieldTypesEnum.BOOLEAN_INPUT: {
      return as<BooleanInputFieldData>({});
    }
    case FormFieldTypesEnum.ADDRESS_INPUT: {
      return as<AddressInputFieldData>({});
    }
    case FormFieldTypesEnum.RICH_TEXT_INPUT: {
      return as<RichTextInputFieldData>({});
    }
    case FormFieldTypesEnum.HTML_DISPLAY: {
      return as<HtmlDisplayFieldData>({});
    }
    case FormFieldTypesEnum.DATE_INPUT: {
      return as<DateInputFieldData>({});
    }
    case FormFieldTypesEnum.TIME_INPUT: {
      return as<TimeInputFieldData>({});
    }
    case FormFieldTypesEnum.HEADLINE_DISPLAY: {
      return as<HeadlineDisplayFieldData>({});
    }
    case FormFieldTypesEnum.PHONE_NUMBER_INPUT: {
      return as<PhoneNumberInputFieldData>({});
    }
    case FormFieldTypesEnum.DATE_TIME_INPUT: {
      return as<DateTimeInputFieldData>({});
    }
    case FormFieldTypesEnum.CONTACT_SINGLE_SELECT: {
      return as<ContactSingleSelectFieldData<false>>({});
    }
    case FormFieldTypesEnum.STATIC_SINGLE_SELECT: {
      return as<StaticSingleSelectFieldData>({});
    }
    case FormFieldTypesEnum.ORGANIZATION_SINGLE_SELECT: {
      return as<OrganizationSingleSelectFieldData<false>>({});
    }
    case FormFieldTypesEnum.STATIC_MULTI_SELECT: {
      return as<StaticMultiSelectFieldData>({
        selectedValues: [],
      });
    }
    case FormFieldTypesEnum.ASSET_MULTI_SELECT: {
      return as<AssetMultiSelectFieldData<false>>({
        entries: [],
      });
    }
    case FormFieldTypesEnum.TIME_TRACKING_LIST_INPUT: {
      return as<TimeTrackingListInputFieldData<false>>({
        entries: [],
      });
    }
    case FormFieldTypesEnum.FILE_UPLOAD: {
      return as<FileUploadFieldData<false>>({
        entries: [],
      });
    }
    case FormFieldTypesEnum.FIELD_REPEATER: {
      return as<RepeaterFieldData<false>>({
        entries: [],
      });
    }
    case FormFieldTypesEnum.PART_LIST_INPUT: {
      return as<PartListInputFieldData>({
        entries: [],
      });
    }
    case FormFieldTypesEnum.SPARE_PART_LIST_INPUT: {
      return as<SparePartListInputFieldData<false>>({
        entries: [],
      });
    }
    case FormFieldTypesEnum.EXPENSE_LIST_INPUT: {
      return as<ExpenseListInputFieldData>({
        entries: [],
      });
    }
    case FormFieldTypesEnum.TASK_LIST_INPUT: {
      return as<TaskListInputFieldData>({
        entries: [],
      });
    }
    case FormFieldTypesEnum.PERSON_LIST_INPUT: {
      return as<PersonListInputFieldData>({
        entries: [],
      });
    }
    default:
      throw new UnreachableCaseError(field);
  }
}
